{
  "host": "https://interestingresearch.org:3000/",
  "title": "Coding Tool",
  "taskCompleteMessage": "Thanks for completing this task, please click below to continue.",
  "autoSubmit": false,
  "autoStart": false,
  "displayedColumns": [
    "task",
    "recording",
    "maxReward",
    "timeLimit",
    "result"
  ],
  "answerFeedback": [
    "Correct answer",
    "Incorrect answer"
  ],
  "redirect": {
    "mode": "result"
  },
  "codingStatusMessage": [
    "You are currently <strong>CODING</strong>",
    "You are currently <strong>WATCHING</strong>"
  ],
  "submitCodeButtonText": "Submit code",
  "takeoverButtonText": "Takeover",
  "submitCodeTitle": "Submit code",
  "submitCodeMessage": "Are you sure to submit your code and end the session?",
  "displayTimeRemaining": true,
  "displayRewards": true,
  "displaySubmitButton": true,
  "displayTakeoverButton": true,
  "minTakeoverTime": 3,
  "useConsole": true,
  "useSyntaxHighlighting": true,
  "startingText": "",
  "editorMinHeight": "350px",
  "editorMaxHeight": "auto",
  "editorWidth": "auto",
  "wrapText": false,
  "disableCopyPaste": true,
  "redactedPlayMode": false
}

