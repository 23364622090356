// Coding Tool
// signin.js
// Author: In Gim <in.gim@yale.edu>

import * as config from './config.json';

let signUpEmailInput = document.getElementById("signUpEmailInput");
let signUpPasswordInput = document.getElementById("signUpPasswordInput");
let signUpRepeatPasswordInput = document.getElementById(
    "signUpRepeatPasswordInput"
);
let signUpAuthCodeInput = document.getElementById("signUpAuthCodeInput");
let signUpAlert = document.getElementById("signUpAlert");

let signUpButton = document.getElementById("signUpButton");

let signInEmailInput = document.getElementById("signInEmailInput");
let signInPasswordInput = document.getElementById("signInPasswordInput");
let signInAlert = document.getElementById("signInAlert");

let signInButton = document.getElementById("signInButton");

signUpButton.addEventListener("click", onSignUpButtonClick);
signInButton.addEventListener("click", onSignInButtonClick);


signUpAlert.style.display = "none";
signInAlert.style.display = "none";

function onSignUpButtonClick() {
    if (signUpPasswordInput.value !== signUpRepeatPasswordInput.value) {
        signUpAlert.style.display = "block";
        signUpAlert.innerHTML = "Password and repeated password do not match";
        return;
    }

    signUpAlert.style.display = "none";
    signUpEmailInput.disabled = true;
    signUpPasswordInput.disabled = true;
    signUpRepeatPasswordInput.disabled = true;
    signUpAuthCodeInput.disabled = true;
    signUpButton.classList.add("disabled");

    fetch(config.host + "create-user", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            email: signUpEmailInput.value,
            password: signUpPasswordInput.value,
            auth: signUpAuthCodeInput.value,
        }),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            console.log(data);

            signUpEmailInput.disabled = false;
            signUpPasswordInput.disabled = false;
            signUpRepeatPasswordInput.disabled = false;
            signUpAuthCodeInput.disabled = false;
            signUpButton.classList.remove("disabled");

            if (data.error) {
                signUpAlert.style.display = "block";
                signUpAlert.innerHTML = data.error;
            } else {
                bootstrap.Modal.getInstance(
                    document.getElementById("signUpModal")
                ).hide();
            }
        });
}

function onSignInButtonClick() {
    signInAlert.style.display = "none";
    signInEmailInput.disabled = true;
    signInPasswordInput.disabled = true;
    signInButton.classList.add("disabled");

    fetch(config.host + "get-user-token", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
            email: signInEmailInput.value,
            password: signInPasswordInput.value,
        }),
    })
        .then((res) => {
            return res.json();
        })
        .then((data) => {

            signInEmailInput.disabled = false;
            signInPasswordInput.disabled = false;
            signInButton.classList.remove("disabled");

            if (data.error) {
                signInAlert.style.display = "block";
                signInAlert.innerHTML = data.error;
            } else {
                window.location.href = 'survey-response-list.html?userToken=' + data.userToken + '&';
            }
        });
}
